<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>已领取优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>手机号码:</span>
      <el-input
        v-model.trim="list.phone"
        placeholder="请输入手机号码"
      ></el-input>
      <span>核销码:</span>
      <el-input
        v-model.trim="list.couponCode"
        placeholder="核销码"
      ></el-input>
      <span>状态:</span>
      <el-select
        clearable
        @clear="onClear(2)"
        v-model="list.status"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getCouponList()">查找</el-button>
       <!-- <el-button type="primary" @click="$router.push(`/addCoupon/0`)"
      v-if=" $store.state.powerList.indexOf('coupon:info:list:add') !== -1"
        >添加</el-button
      > -->
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userCouponId"
        label="优惠券ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="会员姓名"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="手机号码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="couponName"
        label="优惠券名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="couponCode"
        label="核销码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="couponPrice"
        label="优惠券价格"
      ></el-table-column>
      <el-table-column
        align="center"
        prop=""
        label="有效期"
        width="180"
      >
       <template slot-scope="{ row }">
           <div>{{row.beginTime}}</div>
           <div>到</div>
           <div>{{row.endTime}}</div>
       </template>
      </el-table-column>
     
      <el-table-column
        align="center"
        prop="addTime"
        label="	领取时间"
        width="200"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="状态"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-if="row.status !== 1"
            @click="onChangeStatus(row.userCouponId,1)"
            >核销</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="row.status !== 1"
            @click="onChangeStatus(row.userCouponId,2)"
            >强制核销</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { tableListAPI,handle1API,handle2API } from "../../../api/coupon";
export default {
  name: "couponList",
  data() {
    return {
       fit:'fill',
      tableData: [],
      pagination: {},
      ids: "",
      options: [
        {
          value: 1,
          label: "手动领取",
        },
        {
          value: 2,
          label: "注册领取",
        },
      ],
      options1: [
        {
          value: -2,
          label: "全部",
        }, {
          value: 0,
          label: "未使用",
        },
        {
          value: 1,
          label: "已使用",
        },
        {
          value: 2,
          label: "已过期",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        phone: "",
        status: null,
        couponCode:''
      },
       dialogVisible: false,
      QRCode:'',
    };
  },
  created() {
    this.getCouponList();
  },
  methods: {
     handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {})
     },
     downLoadQRCode(){
        //  var url = this.QRCode;
        //  var aLink = document.createElement("a");
        //  aLink.style.display = "none";
        //  aLink.href = url;
        //  aLink.setAttribute("download", '优惠券二维码');
        //  document.body.appendChild(aLink);
        //  aLink.click();
        //  document.body.removeChild(aLink); //下载完成移除元素

     },
    async goCouponQRCode(couponId){
      this.dialogVisible = true;
      const {data} = await couponQRCode({
        couponId:couponId,
      })
    if(data.code == 0){
      console.log(data.msg)
      this.QRCode = data.msg
      console.log('图片',this.QRCode)
    }
    },
    async getCouponList() {
      const { data } = await tableListAPI(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onChangeStatus(id,index) {
        console.log(id,index,'id')
        let text
        index==1?text='核销':text='强制核销'
      this.$confirm(`是否要${text}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
            let API
            index==1?API=handle1API:API=handle2API
            console.log(API,'api')
          const { data } = await API({
            userCouponId: id,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: 0,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getCouponList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getCouponList();
    },
  },
};
</script>
<style lang="less" scoped>
.couponList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 300px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
    
  }
}
</style>